import { Fragment } from "preact";
import OptionsHeader from "./options-header";
import Ingredients from "./ingredients";
import Hydration from "./hydration";

const Layout = () => {
  return (
    <Fragment>
      <div>
        <OptionsHeader />
        <Ingredients />
        <Hydration />
      </div>
      <footer>
        © {new Date().getFullYear()}{" "}
        <a href="https://matanich.com">Tyson Matanich</a>
      </footer>
    </Fragment>
  );
};

export default Layout;
