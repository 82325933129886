import { useGlobalState } from "../global-state";
import { round } from "../helpers";
import style from "./style.scss";
import { useId } from "preact/hooks";
import Textbox from "../textbox";

const Flour = ({ name }) => {
  const id = useId();

  const {
    setFlourWeight,
    setIngredients,
    mode,
    units,
    flourWeight,
    ingredients,
  } = useGlobalState();

  const onInput = (e) => {
    const updatedFlourWeight = isNaN(e.target.valueAsNumber)
      ? 0
      : e.target.valueAsNumber;

    // Update all other ingredient's weight and percent
    let updatedIngredients = [...ingredients];
    for (let i = 0; i < updatedIngredients.length; i++) {
      if (mode == "weight") {
        if (updatedFlourWeight > 0 && updatedIngredients[i].weight > 0) {
          updatedIngredients[i].percent =
            (updatedIngredients[i].weight / updatedFlourWeight) * 100;
        } else {
          updatedIngredients[i].percent = 0;
        }
      } else {
        updatedIngredients[i].weight =
          updatedFlourWeight * (updatedIngredients[i].percent * 0.01);
      }
    }

    // console.group("Flour");
    // console.log(flourWeight, updatedFlourWeight);
    // console.log(ingredients, updatedIngredients);
    // console.groupEnd();

    setFlourWeight(updatedFlourWeight);
    setIngredients(updatedIngredients);
  };

  let displayWeight = round(flourWeight, units == "g" ? 2 : 3);
  if (displayWeight == 0) {
    displayWeight = "";
  }

  return (
    <li class={style.ingredient}>
      <label for={id}>{name}</label>
      <Textbox
        id={id}
        min="0"
        placeholder="0"
        type="number"
        inputmode="decimal"
        value={mode == "weight" ? displayWeight : 100}
        suffix={mode == "weight" ? units : "%"}
        onInput={onInput}
        disabled={mode != "weight"}
      />
      <Textbox
        min="0"
        placeholder="0"
        type="number"
        inputmode="decimal"
        value={mode == "weight" ? 100 : displayWeight}
        suffix={mode == "weight" ? "%" : units}
        onInput={onInput}
        disabled={mode == "weight"}
      />
    </li>
  );
};

export default Flour;
