import { useGlobalState } from "../global-state";
import style from "./style.scss";
import ToggleList from "../toggle-list";

const gramsToOunces = (grams) => {
  return grams / 28.34952;
};

const ouncesToGrams = (ounces) => {
  return ounces * 28.34952;
};

const OptionsHeader = () => {
  const {
    setFlourWeight,
    setIngredients,
    setMode,
    setUnits,
    mode,
    units,
    flourWeight,
    ingredients,
  } = useGlobalState();

  const onUnitsChange = (newUnits) => {
    const updatedFlourWeight =
      newUnits == "g" ? ouncesToGrams(flourWeight) : gramsToOunces(flourWeight);

    let updatedIngredients = [...ingredients];
    for (let i = 0; i < updatedIngredients.length; i++) {
      let updatedWeight = updatedIngredients[i].weight;
      updatedIngredients[i].weight =
        newUnits == "g"
          ? ouncesToGrams(updatedWeight)
          : gramsToOunces(updatedWeight);
    }

    // console.group("Units Change");
    // console.log(flourWeight, updatedFlourWeight);
    // console.log(ingredients, updatedIngredients);
    // console.groupEnd();

    setFlourWeight(updatedFlourWeight);
    setIngredients(updatedIngredients);
  };

  return (
    <header class={style.optionsHeader}>
      <ToggleList
        selected={mode}
        setFunction={setMode}
        options={[
          { name: "Weight", value: "weight" },
          { name: "Percent", value: "percent" },
        ]}
      ></ToggleList>
      <ToggleList
        selected={units}
        setFunction={setUnits}
        options={[
          { name: "Grams", value: "g" },
          { name: "Ounces", value: "oz" },
        ]}
        onChange={onUnitsChange}
      ></ToggleList>
    </header>
  );
};

export default OptionsHeader;
