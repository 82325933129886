import { useGlobalState } from "../global-state";
import { useMemo } from "preact/hooks";
import { round } from "../helpers";
import style from "./style.scss";

const Hydration = ({}) => {
  const { flourWeight, ingredients, units } = useGlobalState();

  const results = useMemo(() => {
    let hydration = 0;
    let weight = 0;

    // console.group("Hydration");
    // console.log("Flour: ", flourWeight);

    weight += flourWeight;
    let dryTotal = flourWeight;
    let wetTotal = 0;
    for (let i = 0; i < ingredients.length; i++) {
      let ingredient = ingredients[i];
      weight += ingredient.weight;
      if (ingredient.waterContent) {
        const dry =
          ingredient.weight * ((100 - ingredient.waterContent) * 0.01);
        const wet = ingredient.weight * (ingredient.waterContent * 0.01);
        dryTotal += dry;
        wetTotal += wet;
        //console.log(`${ingredient.name}: `, dry, wet, dryTotal, wetTotal);
      }
    }

    // https://www.kingarthurbaking.com/blog/2023/01/11/bread-hydration
    hydration = dryTotal > 0 && wetTotal > 0 ? (wetTotal / dryTotal) * 100 : 0;

    // console.log("Dry Total: ", dryTotal);
    // console.log("Wet Total: ", wetTotal);
    // console.log("Hydration: ", result);
    // console.groupEnd();

    return {
      hydration: hydration,
      weight: weight,
    };
  }, [flourWeight, ingredients]);

  return (
    <footer class={style.hydration}>
      <div>
        Hydration {round(results.hydration, 1)}
        <span class={style.symbol}>%</span>
      </div>
      <div>
        Weight {round(results.weight, 1)}
        <span class={style.symbol}>{units}</span>
      </div>
    </footer>
  );
};

export default Hydration;
