import style from "./style.scss";
import { useId } from "preact/hooks";

const ToggleList = ({ selected, options, setFunction, onChange }) => {
  const name = useId();

  const onClick = (e) => {
    const newUnits = e.target.value;
    if (newUnits != selected) {
      setFunction(newUnits);
      if (onChange) {
        onChange(newUnits);
      }
    }
  };

  return (
    <div class={style.toggleList}>
      {options.map((item, index) => (
        <label key={index}>
          <span>{item.name}</span>
          <input
            type="radio"
            name={name}
            value={item.value}
            checked={item.value == selected}
            onClick={onClick}
          />
        </label>
      ))}
    </div>
  );
};

export default ToggleList;
