import { useGlobalState } from "../global-state";
import { round } from "../helpers";
import style from "./style.scss";
import { useId } from "preact/hooks";
import Textbox from "../textbox";

const Ingredient = ({ index, name, weight, percent }) => {
  const id = useId();

  const { setIngredients, mode, units, ingredients, flourWeight } =
    useGlobalState();

  const onInput = (e) => {
    let updatedIngredients = [...ingredients];

    const value = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;

    // Update this specific ingredient's weight and percent
    if (mode == "weight") {
      updatedIngredients[index].weight = value;
      if (flourWeight > 0 && value > 0) {
        updatedIngredients[index].percent = (value / flourWeight) * 100;
      } else {
        updatedIngredients[index].percent = 0;
      }
    } else {
      updatedIngredients[index].weight = flourWeight * (value * 0.01);
      updatedIngredients[index].percent = value;
    }

    // console.group("Ingredients", name);
    // console.log(ingredients, updatedIngredients);
    // console.groupEnd();

    setIngredients(updatedIngredients);
  };

  let displayWeight = round(weight, units == "g" ? 2 : 3);
  if (displayWeight == 0) {
    displayWeight = "";
  }
  let displayPercent = round(percent, 1);
  if (displayPercent == 0) {
    displayPercent = "";
  }

  return (
    <li class={style.ingredient}>
      <label for={id}>{name}</label>
      <Textbox
        id={id}
        min="0"
        placeholder="0"
        type="number"
        inputmode="decimal"
        value={mode == "weight" ? displayWeight : displayPercent}
        suffix={mode == "weight" ? units : "%"}
        onInput={onInput}
      />
      <Textbox
        min="0"
        placeholder="0"
        type="number"
        inputmode="decimal"
        value={mode == "weight" ? displayPercent : displayWeight}
        suffix={mode == "weight" ? "%" : units}
        disabled
      />
    </li>
  );
};

export default Ingredient;
