import style from "./style.scss";

const Textbox = ({ id, value, suffix, onInput, ...props }) => {
  return (
    <div class={style.textbox}>
      <div class={style.suffix} aria-hidden="true" data-suffix={suffix}>
        {suffix}
      </div>
      <input id={id} value={value} onInput={onInput} {...props} />
    </div>
  );
};

export default Textbox;
