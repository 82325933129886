import { useGlobalState } from "../global-state";
import style from "./style.scss";
import Flour from "../flour";
import Ingredient from "../ingredient";

const Ingredients = () => {
  const { ingredients } = useGlobalState();

  return (
    <ul class={style.ingredients}>
      <Flour name="Flour" />
      {ingredients.map((ingredient, index) => (
        <Ingredient key={index} index={index} {...ingredient} />
      ))}
    </ul>
  );
};

export default Ingredients;
