import { createContext } from "preact";
import { useContext, useState } from "preact/hooks";

export const GlobalStateContext = createContext({
  setMode: (mode) => {},
  setUnits: (unit) => {},
  setFlourWeight: (weight) => {},
  setIngredients: (ingredients) => {},
  mode: "weight", // 'weight' | 'percent'
  units: "g", // 'g' | 'oz'
  flourWeight: 0,
  ingredients: [
    { name: "Water", weight: 0, percent: 0, waterContent: 100 },
    { name: "Starter", weight: 0, percent: 0, waterContent: 50 },
    { name: "Salt", weight: 0, percent: 0 },
    { name: "Sugar", weight: 0, percent: 0 },
    { name: "Oil", weight: 0, percent: 0 },
  ],
});

//export const GlobalStateConsumer = GlobalStateContext.Consumer;

export const GlobalStateProvider = ({ children }) => {
  const [units, setUnits] = useState("g");
  const [mode, setMode] = useState("weight");
  const [flourWeight, setFlourWeight] = useState(0);
  const [ingredients, setIngredients] = useState([
    { name: "Water", weight: 0, percent: 0, waterContent: 100 },
    { name: "Starter", weight: 0, percent: 0, waterContent: 50 },
    { name: "Salt", weight: 0, percent: 0 },
    { name: "Sugar", weight: 0, percent: 0 },
    { name: "Oil", weight: 0, percent: 0 },
  ]);

  return (
    <GlobalStateContext.Provider
      value={{
        setMode,
        setUnits,
        setFlourWeight,
        setIngredients,
        mode,
        units,
        flourWeight,
        ingredients,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};
